import React from 'react';

function Drone2({width, height}) {
	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="#002747" stroke="#002747" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M15.95,15.95A7,7,0,1,1,18,11" fill="none" strokeLinecap="butt"/>
                <path d="M32.05,32.05A7,7,0,1,0,37,30" fill="none" strokeLinecap="butt"/>
                <path d="M32.05,15.95A7,7,0,1,1,37,18" fill="none" strokeLinecap="butt"/>
                <path d="M15.95,32.05A7,7,0,1,0,18,37" fill="none" strokeLinecap="butt"/>
                <polygon fill="none" points="28 28 20 28 19 19 29 19 28 28" stroke="#002747" strokeLinecap="butt"/>
                <line fill="none" stroke="#002747" strokeLinecap="butt" x1="11" x2="19" y1="11" y2="19"/>
                <line fill="none" stroke="#002747" strokeLinecap="butt" x1="28" x2="37" y1="28" y2="37"/>
                <line fill="none" stroke="#002747" strokeLinecap="butt" x1="29" x2="37" y1="19" y2="11"/>
                <line fill="none" stroke="#002747" strokeLinecap="butt" x1="11" x2="20" y1="37" y2="28"/>
            </g>
        </svg>
	);
};

export default Drone2;