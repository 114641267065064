import React from 'react';

function VrHeadset({width, height}) {
	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="#002747" stroke="#002747" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M12.114,26.933C14.559,32.323,19.276,36,24,36s9.441-3.677,11.886-9.067" fill="none" stroke="#002747" strokeLinecap="butt"/>
                <path d="M36.635,10a13.978,13.978,0,0,0-25.27,0" fill="none" stroke="#002747" strokeLinecap="butt"/>
                <path d="M42,24V13a3,3,0,0,0-3-3H9a3,3,0,0,0-3,3V24a3,3,0,0,0,3,3H19l2.119-3.391a3.4,3.4,0,0,1,5.762,0L29,27H39A3,3,0,0,0,42,24Z" fill="none"/>
                <path d="M17,33.321v3.248A6,6,0,0,1,12.765,42.3L4,45" fill="none" stroke="#002747" strokeLinecap="butt"/>
                <path d="M44,45l-8.765-2.7A6,6,0,0,1,31,36.569V33.321" fill="none" stroke="#002747" strokeLinecap="butt"/>
                <line fill="none" x1="12" x2="36" y1="16" y2="16"/>
            </g>
        </svg>
	);
};

export default VrHeadset;