import React from 'react';

function Mail({width, height}) {
	return (
        <svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="#111111" stroke="#111111" strokeLinecap="square" strokeWidth="2">
                <path d="M2,21V38a4,4,0,0,0,4,4H42a4,4,0,0,0,4-4V21" fill="none"/>
                <path d="M46,14V10a4,4,0,0,0-4-4H6a4,4,0,0,0-4,4v4L24,28Z" fill="none" stroke="#111111"/>
            </g>
        </svg>
	);
};

export default Mail;

