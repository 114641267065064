import React from 'react';

function MapPin({width, height}) {
	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="#002747" stroke="#002747" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <polyline fill="none" points="40,11.5 46,10 46,40 30,44 18,40 2,44 2,14 8,12.5 " stroke="#002747"/>
                <path d="M34,12 c0,6.172-10,16.25-10,16.25S14,18.172,14,12c0-6.328,5.168-10,10-10S34,5.672,34,12z" fill="none"/>
                <circle cx="24" cy="12" fill="none" r="3"/>
            </g>
        </svg>
	);
};

export default MapPin;